import React from 'react';
import {
  string,
  bool,
  arrayOf,
  shape,
  func,
  oneOfType,
  node,
} from 'prop-types';
import Select from 'react-select';
import classNames from 'classnames';

const propTypes = {
  name: string.isRequired,
  label: string,
  error: string,
  isTouched: bool,
  value: shape({
    label: oneOfType([string, node]).isRequired,
    value: string.isRequired,
  }),
  placeholder: string,
  onChange: func,
  onBlur: func,
  IconComponent: func,
  clearable: bool,
  disabled: bool,
  options: arrayOf(
    shape({
      label: oneOfType([string, node]).isRequired,
      value: string.isRequired,
    })
  ),
  getOptionValue: func,
  background: string,
};

const defaultProps = {
  label: '',
  error: '',
  isTouched: false,
  value: {},
  placeholder: '',
  background: '',
  onChange: () => { },
  onBlur: () => { },
  IconComponent: null,
  clearable: true,
  disabled: false,
  options: [],
};

class WrappedSelect extends React.Component {
  static propTypes = propTypes;
  static defaultProps = defaultProps;

  handleChange = option => {
    this.props.onChange(this.props.name, option);
  };

  handleBlur = () => {
    this.props.onBlur(this.props.name, true);
  };

  render() {
    const {
      isTouched,
      IconComponent,
      formPrefix,
      name,
      label,
      options,
      error,
      disabled,
      placeholder,
      clearable,
      background,
      value,
      className,
      ...rest
    } = this.props;
    const id = formPrefix ? `${formPrefix}_${name}` : name;
    const wrapperClasses = classNames([
      'WrappedField',
      { 'WrappedField--invalid': error && isTouched },
      { 'WrappedField--valid': !error && isTouched },
      { 'WrappedField--gray': background == 'gray' },
      className
    ]);

    return (
      <div className={wrapperClasses}>
        {label &&
          <label className="WrappedField__label" htmlFor={id}>
            {IconComponent && <IconComponent className="WrappedField__icon" />}

            {label}
          </label>
        }

        <span className="WrappedField__message">
          {error && isTouched ? error : ''}
        </span>

        <Select
          clearable={clearable}
          placeholder={placeholder}
          disabled={disabled}
          className="WrappedField__select"
          id={id}
          options={options}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          blurInputOnSelect
          classNamePrefix="rs"
          {...rest}
        />
      </div>
    );
  }
}

export default WrappedSelect;
