import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

import classNames from 'classnames';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import SVG from 'react-inlinesvg';

import Layout from '../components/Layout';
import WrappedField from '../components/WrappedField';
import Button from '../components/Button';
import Form from '../components/Form';
import StatementContact from '../components/StatementContact';
import CardStatic from '../components/CardStatic';

import green_lines from '../img/svg/pageSolutions/green_lines.svg';
import green_lines2 from '../img/svg/pageSolutions/green_lines2.svg';
import opinion_bottom from '../img/svg/pageSolutions/opinion_bottom.svg';
import opinion_top from '../img/svg/pageConsultation/opinion_top.png';
import polyline1 from '../img/svg/pageSolutions/polyline1.svg';
import polyline2 from '../img/svg/pageSolutions/polyline2.svg';

import BackgroundSrc from '../img/svg/pageServices/hero_bg.svg';
import ImageStarsSrc from '../img/svg/stars.svg';
import WrappedSelect from '../components/WrappedSelect';

export const ConsultationPageTemplate = (props) => {
  const [isMainSVGLoaded, setIsMainSVGLoaded] = React.useState(false);

  const classNameBackground = classNames('Consultation__hero__background', {
    'Consultation__hero__background--noImage': !isMainSVGLoaded
  });

  const { hero, areas, experts, result, contact } = props;

  return (
    <div className="Consultation">
      <section className="Consultation__hero">
        <div className="Layout__background">
          <SVG src={ImageStarsSrc} className="Layout__stars" />
        </div>

        <div className={classNameBackground}>
          <SVG
            src={BackgroundSrc}
            uniquifyIDs={false}
            className="Consultation__hero__background-image"
            onError={error => console.log(error.message)}
            onLoad={(src, hasCache) => setIsMainSVGLoaded(true)}
          />
          <div className="Consultation__hero__background__bottom"></div>
        </div>

        <div className="Consultation__hero__container container mx-auto grow flex flex-col justify-between">
          <div className="grid sm:grid-cols-2 md:gap-8 my-auto items-start sm:py-14 relative">
            <div className="text-lg mb-12 sm:mb-0 relative sm:pr-16 sm:mt-0">
              <h1 className="text-4xl xs:leading-normal font-bold text-secondary mb-5 sm:pt-14">
                {hero.title}
              </h1>
              <ReactMarkdown
                children={hero.text}
                rehypePlugins={[rehypeRaw]}
              />
            </div>
            <div className="relative flex justify-center">
              <img src={green_lines} width="257" height="257" alt="" className='absolute z-0 bottom-7 -left-16 hidden sm:block' />
              <div className="relative">
                <div className="Consultation__form sm:p-5 bg-white">
                  <ContactForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-24 relative">
        <div className="container mx-auto relative z-10">
          <div className="text-center max-w-4xl mx-auto">
            <h2 className="text-3xl xs:text-4xl mb-12 leading-tight xs:leading-tight font-bold text-white">
              {areas.title}
            </h2>
            <p className="mb-12 sm:mb-14 md:mb-20 text-white">
              We are Big Data experts working with international clients to create leading innovative projects related to the Big Data environment. We implement a wide range of <strong>ML/AI, Data Platform, Data Analytics and Data Science Projects</strong> for international clients from start-ups to worldwide corporations. During the Consultation-sharing session we can discuss:
            </p>
          </div>
          <div className="flex flex-wrap -m-4">
            {areas.items.map((item, i) => (
              <div className="p-4 w-1/2 md:w-1/4" key={i}>
                <CardStatic
                  key={1}
                  tagName="div"
                  className="h-full CardStatic--align-start CardStatic--title-m"
                  iconSrc={item.icon && item.icon.publicURL}
                  title={item.title}
                  text={item.description}
                >
                  {item.description}
                </CardStatic>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="relative mt-16">
        <img src={opinion_top} alt="" loading="lazy" width="1600" decoding="async" className='SolutionsNew__opinion__top' />
        <div className="bg-secondary -mt-1 py-4 sm:py-22 px-6 relative z-10">
          <img src={green_lines2} width="257" height="257" alt="" className="hidden md:block absolute z-0 -bottom-28 -right-32" />
          <div className="container mx-auto relative z-10">
            <div className="text-center max-w-4xl mx-auto">
              <h2 className="text-3xl xs:text-4xl mb-12 leading-tight xs:leading-tight font-bold text-white">
                Meet our Experts
              </h2>
              <p className="mb-12 sm:mb-14 md:mb-20 text-white">
                During the consultation, you will be able to speak with one of our Data, ML, Analytics, Streaming Experts
              </p>
            </div>
            <div className="flex flex-wrap -m-4">
              {experts.items.map((item, i) => (
                <div className="p-4 w-1/2 sm:w-1/4" key={i}>
                  <div className="h-full flex flex-col items-center text-center">
                    <img alt="team" className="flex-shrink-0 rounded-lg w-full h-30 md:h-56 object-cover object-center mb-4 " src={item.image && item.image.publicURL} />
                    <div className="w-full">
                      <h2 className="text-xl font-medium text-white mb-2">
                        {item.title}
                      </h2>
                      <h3 className="text-sm text-white mb-3">
                        {item.description}
                      </h3>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <img src={opinion_bottom} alt="" loading="lazy" width="1600" height="367" decoding="async" className='SolutionsNew__opinion__bottom bg-secondary' />
      </section>

      <section className="bg-background-blue py-16">
        <div className="container mx-auto">
          <div className="flex flex-wrap">
            <div className="p-6 sm:w-1/2 md:w-[40%]">
              <h2 className="text-3xl sm:text-4xl sm:mb-12 leading-tight sm:leading-tight font-bold text-secondary">
                Why do you get exactly <br />
                2^5 minutes with our experts?
              </h2>
            </div>
            {result.items.map((item, i) => (
              <div className={`p-6 sm:w-1/2 ${(i + 1) % 3 === 0 ? 'md:w-[40%]' : 'md:w-[30%]'}`} key={i} >
                <div className="flex sm:mb-12 relative z-0">
                  <ResultItem item={item} i={i + 1} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="flex flex-col relative py-16 bg-white">
        <img src={polyline1} alt="" width="425" height="478" loading="lazy" decoding="async" className='absolute -left-24 top-1/2 -mt-96 z-0' />
        <img src={polyline2} alt="" width="525" height="591" loading="lazy" decoding="async" className='absolute -right-16 top-1/2 -mt-80 z-0' />
        <div className="container mx-auto grow flex flex-col justify-between">
          <div className="grid sm:grid-cols-2 md:gap-8 my-auto items-start relative">
            <div className="text-lg mb-12 sm:mb-0 relative sm:pr-16 sm:mt-0">
              <h1 className="text-4xl xs:leading-normal font-bold text-secondary mb-5 sm:pt-14">
                {contact.title}
              </h1>
              <ReactMarkdown
                children={contact.text}
                rehypePlugins={[rehypeRaw]}
              />
            </div>
            <div className="relative flex justify-center">
              <img src={green_lines} width="257" height="257" alt="" className='absolute z-0 bottom-7 -left-16 hidden sm:block' />
              <div className="relative">
                <div className="Consultation__form sm:p-5 bg-white">
                  <ContactForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
};


function ResultItem({ item, i }) {
  return (
    <>
      <div className="absolute text-[115px] xs:text-[135px] sm:text-[180px]" style={{
        lineHeight: '0.75',
        left: '0',
        right: '0',
        top: '0',
        textAlign: 'left',
        fontFamily: 'sans-serif',
        fontWeight: '700',
        color: '#adadad',
        opacity: '.3',
      }}>
        2^{i}
      </div>
      <div className="z-10">
        <h3 className="text-lg sm:text-2xl mb-4 sm:mb-6 leading-tight sm:leading-tight font-bold text-secondary">
          {item.title}
        </h3>
        <p className="font-normal sm:font-medium">
          <ReactMarkdown
            children={item.description}
            rehypePlugins={[rehypeRaw]}
          />
        </p>
      </div>
    </>
  )
};

function ContactForm() {
  const [hasSubmitSucceeded, setHasSubmitSucceeded] = React.useState(false);
  const [hasSubmitFailed, setHasSubmitFailed] = React.useState(false);

  const sendForm = (values) => {
    const formAPI = '/.netlify/functions/hubspot';

    // reset states
    setHasSubmitSucceeded(false);
    setHasSubmitFailed(false);

    axios({
      method: 'get',
      url: formAPI,
      params: {
        function: 'deal.create',
        ...values
      }
    })
      .then(res => {
        res.status === 200 ? setHasSubmitSucceeded(true) : setHasSubmitFailed(true);
      })
      .catch(error => {
        setHasSubmitFailed(true);
      });
  };

  const onSubmit = values => {
    sendForm(values);
  };

  const formSchema = Yup.object().shape({
    name: Yup.string().min(2, 'Name must be at least 2 characters').required('Name is required'),
    email: Yup.string()
      .email('Email is invalid')
      .required('Email is required'),
    how: Yup.string().required('Field is required'),
    area: Yup.string().required('Field is required'),
    message: Yup.string().min(2, 'Message must be at least 2 characters').required('Message is required'),
    gdpr: Yup.boolean().oneOf([true], 'You need to accept it to submit this form'),
  });

  const initialValues = {
    name: '',
    email: '',
    how: '',
    area: '',
    message: '',
    gdpr: false,
  };

  const areaOptions = [
    { value: 'Machine Learning Operations (MLOps)', label: 'Machine Learning Operations (MLOps)' },
    { value: 'Modern Data Platform', label: 'Modern Data Platform' },
    { value: 'Streaming Analytics', label: 'Streaming Analytics' },
    { value: 'Data-Driven Transformation', label: 'Data-Driven Transformation' },
  ];

  let submit = (
    <Button type="submit" className="Contact__submit Form__submit mt-3" variants={['full']}>
      Get a free session
    </Button>
  );
  if (hasSubmitSucceeded) {
    submit = (
      <p className="Form__submit">
        Thanks for reaching out! We will get back to you shortly.
      </p>
    );
  } else if (hasSubmitFailed) {
    submit = (
      <p className="Form__submit">
        Your form was <strong>not</strong> saved. Please reach us at{' '}
        <strong>hello@getindata.com</strong>. Thanks!
      </p>
    );
  }

  return (
    <Formik
      validationSchema={formSchema}
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {({
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
        values,
      }) => (
        <>
          <Form>
            <div className="Form__level Form__level--smallMargin">
              <WrappedField
                name="name"
                type="text"
                error={errors.name}
                isTouched={touched.name}
                placeholder="Name"
                background="gray"
                className="WrappedField--messageSmall"
              />
            </div>

            <div className="Form__level Form__level--smallMargin">
              <WrappedField
                name="email"
                error={errors.email}
                isTouched={touched.email}
                placeholder="Company email"
                background="gray"
                className="WrappedField--messageSmall"
              />
            </div>

            <div className="Form__level Form__level--smallMargin">
              <WrappedField
                name="message"
                error={errors.message}
                isTouched={touched.message}
                placeholder="Your message"
                component="textarea"
                background="gray"
                className="WrappedField--messageSmall leading-none"
              />
            </div>

            <div className="Form__level Form__level--smallMargin">
              <WrappedSelect
                name="area"
                error={errors.area}
                isTouched={touched.area}
                placeholder="Choose area"
                background="gray"
                // defaultValue={areaOptions[0]}
                options={areaOptions}
                onChange={(option) => {
                  setFieldValue('area', option.value);
                }}
                className="WrappedField--messageSmall"
              />
            </div>

            <div className="Form__level Form__level--smallMargin">
              <WrappedField
                name="how"
                error={errors.how}
                isTouched={touched.how}
                placeholder="How did you find us?"
                background="gray"
                className="WrappedField--messageSmall"
              />
            </div>

            <div className="Form__level Form__level--smallMargin">
              <WrappedField
                name="gdpr"
                label="I agree to receive via email marketing information (e.g. about news, products and services, events or training courses)"
                error={errors.gdpr}
                isTouched={touched.gdpr}
                type="checkbox"
                isCheckbox={true}
              />
            </div>

            {submit}

            <div className="Contact__statement mb-0 mt-2">
              <StatementContact />
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
}

const ConsultationPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  const mergedData = {
    ...frontmatter,
  };

  return (
    <Layout
      variants={['backgrounded']}
      meta={frontmatter.meta}
      showContact={false}
    >
      <ConsultationPageTemplate {...mergedData} />
    </Layout>
  );
};

ConsultationPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ConsultationPage;

export const ConsultationPageQuery = graphql`
  query ConsultationPageTemplate {
    markdownRemark(frontmatter: {templateKey: {eq: "consultation-page" } }) {
      frontmatter {
        meta {
          title
          description
          image {
            publicURL
            name
          }
        }
        hero {
          title
          text
        }
        areas {
          title
          description
          items {
            title
            description
            icon {
              publicURL
              name
            }
          }
        }
        experts {
          title
          description
          items {
            title
            description
            image {
              publicURL
              name
            }
          }
        }
        result {
          title
          description
          items {
            title
            description
          }
        }
        contact {
          title
          text
        }
      }
    }
  }
`;
