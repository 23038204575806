import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  iconSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  tagName: PropTypes.string,
  className: PropTypes.string,
  headingLevel: PropTypes.number,
  variants: PropTypes.arrayOf(PropTypes.oneOf(['center'])),
};

const defaultProps = {
  tagName: 'article',
  className: '',
  headingLevel: 3,
  variants: [],
};

function CardStatic({ iconSrc, title, children, tagName, className, headingLevel, variants }) {
  const variantClasses = variants.map(variant => `CardStatic--${variant}`);
  const classes = classNames(className, 'CardStatic', variantClasses);
  const TagName = tagName;
  const Heading = `h${headingLevel}`;

  return (
    <TagName className={classes}>
      <div className="CardStatic__content">
        {iconSrc &&
          <img src={iconSrc} className="CardStatic__icon" alt={title} />
        }

        {title &&
          <Heading className="CardStatic__title">{title}</Heading>
        }

        {children}
      </div>
    </TagName>
  );
}

CardStatic.propTypes = propTypes;
CardStatic.defaultProps = defaultProps;

export default CardStatic;
